import mastersBig from "./../img/projects/masters-big.jpg";
import masters from "./../img/projects/masters.jpg";

import recommenderBig from "./../img/projects/recommender-system-big.jpg";
import recommender from "./../img/projects/recommender-system.jpg";

import lbawBig from "./../img/projects/lbaw-big.jpg";
import lbaw from "./../img/projects/lbaw.jpg";

import lcomBig from "./../img/projects/lcom-big.jpg";
import lcom from "./../img/projects/lcom.jpg";

import iaBig from "./../img/projects/ia-big.jpg";
import ia from "./../img/projects/ia.jpg";

import compBig from "./../img/projects/comp-big.jpg";
import comp from "./../img/projects/comp.jpg";

import snooterBig from "./../img/projects/snooter-big.jpg";
import snooter from "./../img/projects/snooter.jpg";


const projects = [
	{
		title: 'Master Thesis',
		skills: 'Jupyter Notebooks, Python, Google Big Query, Time Series, Causal Discovery, Scikit-learn',
		img: masters,
		imgBig: mastersBig,
		gitHubLink: 'https://www.pmnunes.dev/thesis.pdf',
		description: `
		    <h1>Thesis Summary</h1>
    <p>
        My thesis focuses on integrating Causal Machine Learning (CausalML) techniques to enhance the interpretability and predictive performance of traditional machine learning models, particularly in healthcare settings. I explored a scoring system for ICU admissions to classify mortality risk, proposing a pipeline that incorporates Causal Discovery to improve feature selection. The results demonstrated that models utilizing causal predictors outperform traditional approaches in both interpretability and forecasting, providing a solid foundation for future research in this area.
    </p>
	<br>
    <h2>Technologies Used</h2>
    <p>
        For this project, I employed various technologies, including Python and Jupyter Notebooks for data analysis and model development. I utilized Google BigQuery for efficient data processing, along with the Scikit-learn library for regression models. Data visualization was achieved using Seaborn, enabling clear representation of findings and insights.
    </p>`
	},
	{
		title: 'Recommender System',
		img: recommender,
		imgBig: recommenderBig,
		skills: 'NLP, Recommender Systems, Neural Networks, Pandas',
		gitHubLink: 'https://github.com/Viperxyzzz/CAC',
		description: 
		`
		    <h1>Project Summary</h1>
    <p>
	This project focused on creating a Recommender System for Anime. A lot of problems were tackled such as cold-starts, choosing collaborative filtering, dealing with big datasets, etc.  
    </p>
	<br>
    <h2>Technologies Used</h2>
    <p>
	Two different models were used, one Recommender System using Neural Networks and another using NLP. The Neural Network bases itself on the user already watched animes, whereas the NLP checks for the animes with the most similar description to the animes the user likes the most.
	</p>`

	},
	{
		title: 'Snooter, A decentralized timeline service',
		img: snooter,
		imgBig: snooterBig,
		skills: 'JavaScript, Express, Libp2p, KadDHT (Kademlia), GossipSub, PubSubPeerDiscovery',
		gitHubLink: 'https://github.com/biromiro/feup-sdle-proj2',
		video: 'https://www.youtube.com/embed/ALb8azPCMX4',
		description: 
		`
		    <h1>Project Summary</h1>
<p>
This project explores the creation of a decentralized timeline service, Snooter, a Twitter look alike, that harvests peer-to-peer and edge devices. Users have an identity and are able to publish text messages in their local machine, forming a local timeline. They can subscribe to other users timelines and will help to store and forward their content. Remote content is available when a source or source subscriber is online and can forward the information. Information from subscribed sources is ephermal and only stored and forward for a given time period.
</p>
<br>
<h2>Technologies Used</h2>
<p>
    Javascript libp2p - for implementations of a variety of different peer-to-peer algorithms. For protocols, algorithms and structures, TCP, KadDHT, GossipSub, Bootstrap and PubSubPeerDiscovery were used.
</p>
`
	},
	{
		title: 'IA in Google Traffic Signaling',
		img: ia,
		imgBig: iaBig,
		skills: 'Python, Optimization, Hill Climbing, Simulated Annealing, Genetic Algorithms, Problem Solving',
		gitHubLink: 'https://github.com/Viperxyzzz/FEUP-IA-Proj1',
		description: 
		`
		    <h1>Project Summary</h1>
    <p>
	This project solves the Google Hash Problem - Traffic Signaling, using Optimization Algorithms. 
    </p>
	<br>
    <h2>Approaches and Features</h2>
    <p>
	Three approaches were used, hill climbing, simulated annealing and genetic algorithms. It was also created a funcion that generates new problems for us to test, and another function to run the simulations done.
	</p>`
	},
	{
		title: 'Q&A Website',
		img: lbaw,
		imgBig: lbawBig,
		skills: 'Laravel, PostgreSQL, Docker',
		gitHubLink: 'https://github.com/Viperxyzzz/LBAW-AskFEUP',
		description: 
		`
		    <h1>Project Summary</h1>
<p>
    This project consists of a Q&A forum, similar to Stack Overflow, where users can ask and get their questions answered. Additionally, it supports notifications, comments, achievements, likes, custom profile picture, Google authentication, etc.
</p>
<br>
<h2>Technologies Used</h2>
<p>
    Laravel for Full-Stack, PostgreSQL for database, and Docker for containerization. More about it can be found <a href="https://www.youtube.com/watch?v=Ea-phX-BeK0" target="_blank">here</a>.
</p>
`
	},
	{
		title: 'Java-- Compiler',
		img: comp,
		imgBig: compBig,
		skills: 'Java',
		gitHubLink: 'https://github.com/Viperxyzzz/COMP',
		description: 
		`
		    <h1>Project Summary</h1>
<p>
    A compiler for a subset of Java called Java--. This tool translates programs written in Java-- into java bytecodes, passing through different stages. First, it detectes syntactic errors using a well defined grammar. Then it generates an AST that is semantically analysed, producing a symbol table. From the AST, Ollir code is generated and, finally, from that, the jasmin code is produced.
</p>
<br>
	<h1>Main Points</h1>
	<p>
		Semantic Analysis - Code Generation (Abstract Syntax Tree, Ollir and Jasmin).
		<br>
		We were able to write a grammar that, along with semantic analysis, detects any Java-- language error, whether syntactic or semantic.

		We built a functional and well-structured AST that contains everything needed in the next phases, respecting every rule, like operator precedence.

		The ollir is well functional, three adresses parsing works well and every other feature was implemented successfully.

		The jasmin code is for the most part functional and follows the specifications.
		</p>
	`
	},
	{
		title: 'Drivers and Game for Minix OS',
		img: lcom,
		imgBig: lcomBig,
		skills: 'C, Linux, Virtual Box, Low Level Drivers',
		gitHubLink: 'https://github.com/Viperxyzzz/lcom',
		video: 'https://www.youtube.com/embed/9Hrn1WA12Hc',
		description: 
		`
		    <h1>Project Summary</h1>
<p>
		This project consists of the development of a Timer, Mouse, Keyboard, Video Graphics Card, RTC and UART Drivers for a Minix OS System. Then, to showcase those drivers, a clone of the Atari Breakout was done.
</p>
<br>
<h2>Technologies Used</h2>
<p>
    Drivers were coded using C.
</p>
`
	}
// 	},
// 	{
// 		title: 'Decentralized Twitter Clone',
// 		img: lcom,
// 		imgBig: lcomBig,
// 		skills: 'C, Linux, Virtual Box, Low Level Drivers',
// 		gitHubLink: 'https://github.com/Viperxyzzz/lcom',
// 		description: 
// 		`
// 		    <h1>Project Summary</h1>
// <p>
// 		This project consists of the development of a Timer, Mouse, Keyboard, Video Graphics Card, RTC and UART Drivers for a Minix OS System. Then, to showcase those drivers, a clone of the Atari Breakout was done.
// </p>
// <br>
// <h2>Technologies Used</h2>
// <p>
//     Drivers were coded using C. A video running the project can be found <a href="https://youtu.be/9Hrn1WA12Hc" target="_blank">here</a>.
// </p>
// `
// 	},
// 	,
// 	{
// 		title: 'Drivers and Game for Minix OS',
// 		img: lcom,
// 		imgBig: lcomBig,
// 		skills: 'C, Linux, Virtual Box, Low Level Drivers',
// 		gitHubLink: 'https://github.com/Viperxyzzz/lcom',
// 		description: 
// 		`
// 		    <h1>Project Summary</h1>
// <p>
// 		This project consists of the development of a Timer, Mouse, Keyboard, Video Graphics Card, RTC and UART Drivers for a Minix OS System. Then, to showcase those drivers, a clone of the Atari Breakout was done.
// </p>
// <br>
// <h2>Technologies Used</h2>
// <p>
//     Drivers were coded using C. A video running the project can be found <a href="https://youtu.be/9Hrn1WA12Hc" target="_blank">here</a>.
// </p>
// `
// 	}
];

export {projects}